body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 15vmin;
    pointer-events: none;
}

.App-header {
    background-color: #0d47a1;
    color: white;
}
.bg-blue {
    background-color: #0d47a1;
}

.btn-blue {
    background-color: #0d47a1;
    color: #fff;
}

.btn-blue:hover,
.btn-blue:active {
    background-color: #3467b8;
    color: #fff;
}

.App-link {
    color: #61dafb;
}

span.icon-search img {
    height: 1.5rem;
    display: inline-block;
}

.zip-search .input-group {
    max-width: 500px;
    margin: auto;
}

.navbar-brand a {
    color: #fff;
}

/* Listings */

.listing-card h3,
.listing-card h4,
.listing-card h5 {
    margin: 5px 0 10px;
    padding: 0;
}

.listing-card h4.meeting-name {
    font-size: 0.9em;
    font-weight: 600;
}
.listing-card {
    border: 1px solid #ddd;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 4px;
    font-size: 0.92em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.listing-card__header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
}

.listing-card__img {
    -webkit-align-self: stretch;
            align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: #f7f7f7;
    width: 25%;
    -webkit-flex: 0 1 25%;
            flex: 0 1 25%;
    min-width: 112px;
}

.listing-card__img img {
    width: 85%;
    margin: 0 auto;
}

.listing-card__title {
    text-align: left;
    padding: 5px 5px 0 10px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.listing-card__name {
    font-size: 1.13em;
    letter-spacing: -0.04em;
    font-weight: 500;
    color: #555;
}

.us-bg {
    position: relative;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.state-list {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.location-list li > a {
    display: inline-block;
    padding: 0.3rem 1rem;
    font-weight: 500;
}

